import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import IconPlayButtonTriangle from '@/svgs/play-button-triangle.svg'
import Video from '@/components/Video'

import classes from './FullBleedHero.module.scss'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@/services/sanityClient'

const FullBleedHero = ({ fields, imagePriority }) => {
  const [startVideo, setStartVideo] = useState(false)
  const builder = imageUrlBuilder(sanityClient)

  let {
    title,
    heroStyle,
    smallerText,
    textColor,
    desktopBackgroundImage,
    mobileBackgroundImage,
    imageBrightness,
    alt,
    youtubeVideoId,
    topMargin,
    bottomMargin,
    fixedImage,
  } = fields

  function urlFor(source) {
    return builder.image(source)
  }
  function cropImageUrl(image) {
    if (!image?.asset?.metadata?.dimensions) {
      return image.asset?.url
    }
    const h = image.asset.metadata.dimensions.height
    const w = image.asset.metadata.dimensions.width
    return image.asset.url
      ? image.hotspot
        ? urlFor(image)
            .rect(
              parseInt(image.crop.left * w),
              parseInt(image.crop.top * h),
              parseInt(image.hotspot.width * w),
              parseInt(image.hotspot.height * h),
            )
            .fit('max')
            .auto('format')
            .url()
        : image.asset.url
      : undefined
  }
  const showVideo = () => {
    setStartVideo(true)
  }

  let btnColor
  // Conditionally change the color of the button
  if (textColor === 'text--light') {
    btnColor = 'salmon'
  } else {
    btnColor = 'salmon'
  }

  const imageInlineStyles = {
    filter: `brightness(${imageBrightness ? imageBrightness : 100}%)`,
  }

  const mobileImageInlineStyles = {
    filter: `brightness(${imageBrightness ? imageBrightness : 100}%)`,
  }

  return (
    <div
      className={`${classes['hero']} ${smallerText ? classes['hero--smaller-text'] : ''} ${classes[heroStyle]} ${heroStyle === 'hero--with-container' ? 'container' : ''} ${classes[textColor]} ${topMargin ? classes['top-margin'] : ''} ${bottomMargin ? classes['bottom-margin'] : ''} ${startVideo ? classes['hero--video-enabled'] : ''}`}
    >
      <div className={`${classes['hero__text']}`}>
        <div className={classes['hero__text-inner']}>
          {fields.header && (
            <h1
              className={`${classes['hero__header']}_${classes[textColor]} ${classes[`${heroStyle === 'hero--bottom' ? 'heading--catch' : ''}`]}`}
            >
              {fields.header}
            </h1>
          )}
          {fields.subheader && (
            <h2 className={classes['hero__subheader']}>{fields.subheader}</h2>
          )}

          <div className={classes['btn-wrap']}>
            <div className={classes['primary-btn-wrap']}>
              {fields.primaryCtaUrl && (
                <Link href={`${fields.primaryCtaUrl}`} legacyBehavior>
                  <a
                    className={`uppercase ${classes['hero__primary']} ${classes['btn']} btn ${youtubeVideoId ? 'sitkablue' : btnColor} no-underline`}
                  >
                    {fields.primaryCtaText}
                  </a>
                </Link>
              )}
              <ResponsiveComponent
                desktopBreakpoint={'lg'}
                desktop={
                  youtubeVideoId &&
                  heroStyle !== 'hero--with-container' && (
                    <button
                      onClick={() => showVideo()}
                      className={`${classes['hero-video__action-btn']} btn ${btnColor}`}
                    >
                      <IconPlayButtonTriangle />
                      <span>Play Video</span>
                    </button>
                  )
                }
                mobile={
                  youtubeVideoId &&
                  heroStyle === 'hero--with-container' && (
                    <button
                      onClick={() => showVideo()}
                      className={`${classes['hero-video__action-btn']} btn ${btnColor}`}
                    >
                      <IconPlayButtonTriangle />
                      <span>Play Video</span>
                    </button>
                  )
                }
              />
            </div>
            {fields.secondaryCtaUrl && (
              <Link href={`${fields.secondaryCtaUrl}`} legacyBehavior>
                <a className={classes['hero__secondary']}>
                  {fields.secondaryCtaText}
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
      {heroStyle === 'hero--left' && (
        <div className={classes['hero__prefer_to_talk']}>
          Prefer to Talk?
          <span>&nbsp;Call us to order (309)-342-3474</span>
        </div>
      )}
      <div className={classes['hero__main-wrap']}>
        {youtubeVideoId && heroStyle === 'hero--with-container' && (
          <button
            onClick={() => showVideo()}
            className={`${classes['hero-video__action-btn']} btn ${btnColor}`}
          >
            <IconPlayButtonTriangle />
            <span>Play Video</span>
          </button>
        )}

        <ResponsiveComponent
          desktopBreakpoint={'sm'}
          mobile={
            mobileBackgroundImage && (
              <div
                className={`${classes['hero__wrap']} ${
                  fixedImage ? classes['hero__wrap--mbl'] : ''
                }`}
              >
                <Image
                  className={classes.mbl__img}
                  src={cropImageUrl(mobileBackgroundImage)}
                  fill
                  objectFit="cover"
                  priority={imagePriority}
                  alt={alt || title}
                  style={mobileImageInlineStyles}
                />
              </div>
            )
          }
          desktop={
            desktopBackgroundImage && (
              <div
                className={`${classes['hero__wrap']} ${classes['hero__wrap--dsktp']}`}
              >
                <Image
                  className={classes.mbl__img}
                  src={cropImageUrl(desktopBackgroundImage)}
                  fill
                  objectFit="cover"
                  priority={imagePriority}
                  alt={alt || title}
                  style={imageInlineStyles}
                />
              </div>
            )
          }
        />
      </div>

      {youtubeVideoId && (
        <Video
          youtubeVideoId={youtubeVideoId}
          startVideo={startVideo}
          className={classes['hero-video__wrap']}
        />
      )}
    </div>
  )
}

export default FullBleedHero
