import { captureException } from '@sentry/nextjs';

export async function updateProfile(profileBody) {
  const res = await fetch('/api/klaviyo/klaviyo-update-profile', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: profileBody
  })
    .then(res => res.json())
    .then((data) => {
      responseHandler(data)
    })

  return await handleResponse(res);
}


export async function handleResponse(res) {
  if (res.ok) {
    const { data } = await res.json();
    return data;
  } else {
    const e = await res.json();
    console.error(`${res.status} klaviyo api response: `, e);
    captureException(new Error(JSON.stringify(e)));
    return e;
  }
}